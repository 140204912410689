<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
        class="d-none d-lg-flex align-items-center"
        lg="8"
        :style="logoStyle"
      >
        <div
          class="mx-auto d-lg-flex align-items-center justify-content-center px-5"
          style="width: 70vh;"
        >
          <b-img :src="sideImg" :alt="'Logo de ' + tenantId" fluid />
        </div>
      </b-col>

      <login-form />
    </b-row>
  </div>
</template>

<script>
import { BCardText, BCol, BImg, BLink, BRow, VBTooltip } from "bootstrap-vue";
import store from "@/store";
import LoginForm from "@authnz/components/LoginForm";
import { computed, ref } from "@vue/composition-api";
import { useLocalisation } from "@/shared/composables/use-localisation";
import Tenant from "@/plugins/tenant";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BImg,
    LoginForm,
  },
  setup() {
    const tenantId = Tenant.resolveTenantId();

    let sideImg = ref(require("@/assets/images/logo/transat.png"));
    let logo = require("@/assets/images/logo/mge_logo_en.svg");
    let tenantLogo = sideImg;
    try {
      tenantLogo = require("@/assets/images/logo/" + tenantId + ".png");
    } catch (e) {
      console.debug("unable to load tenant logo", e);
    }
    const { t } = useLocalisation();

    computed(() => {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
      }
    });

    const randomIntFromInterval = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const backgroundImg = ref(
      require("@/assets/images/backgrounds/background" +
        randomIntFromInterval(1, 6) +
        ".jpg")
    );

    return {
      tenantId,
      sideImg: tenantId === "transat" ? sideImg : tenantLogo,
      logo,
      logoStyle:
        "background-size: cover; background-image: linear-gradient(rgba(52, 160, 164, 0.8), rgba(52, 160, 164, 0.8)), url('" +
        backgroundImg.value +
        "'); background-repeat: no-repeat; background-position: center",
      t,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.login-logo {
  height: 7em;
}
</style>
