<template>
  <b-card-text class="text-center mt-2">
    <span> Problèmes de connexion? </span>
    <br />
    <b-link :href="`mailto:${supportEmail}`">
      <span>Contactez-nous</span>
    </b-link>
  </b-card-text>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";
import Tenant from "@/plugins/tenant";

export default {
  name: "contact-us",
  components: {
    BCardText,
    BLink,
  },
  setup() {
    const tenantId = Tenant.resolveTenantId();
    let supportEmail = "servicesreseaux.memberservices@transat.com";

    switch (tenantId) {
      case "transat":
        supportEmail = "servicesreseaux.memberservices@transat.com";
        break;
      default:
        supportEmail = "support@monicagroups.freshdesk.com";
    }

    return {
      supportEmail,
    };
  },
};
</script>

<style scoped>
a,
a:visited,
a:active {
  color: #34a0a4;
  text-decoration: underline;
}

a:hover {
  color: #34a0a4;
  text-decoration: none;
}
</style>
